<template>
  <div id="app">
    <div v-if="step==1">

      <div class="mine_t clear">
        <img class="bg_mine" src="../../../src/assets/images/bg_mine.jpg" alt="">
        <div class="mine_avatar" :style="{backgroundImage:`url(${img})`}"></div>
        <div class="mine_details">
                 <span>
                     {{
                     customerUser != null ? customerUser.businessCustomerUser.name : ''
                   }} <font>{{ customerUser != null ? customerUser.businessCustomerUser.structure.name : '' }}</font>
                 </span>
          <label>{{ customerUser != null ? customerUser.businessCustomerUser.business.name : '' }}</label>
        </div>
      </div>

      <div class="tuition_list border_none">
        <div class="tuition_list_t">
          <span>{{ data.name }}</span>
        </div>

        <div v-for="(option, index) in projectList" :key="index">
          <div class="tuition_choose clear">
            <div class="tuition_choose_r">
                    <span class="clear">
                        {{ option.name }}
                        <font v-if="option.required === 1">必交</font>
                      <font class="gray" v-if="option.required === 0">必交</font>
                    </span>
              <label>￥{{ option.amount }}</label>
            </div>
          </div>
        </div>

        <div class="tuition_choose choose_all clear">
          <div class="tuition_choose_r choose_all">
            <dt></dt>
            <dd class="red_big">{{ totalAmountLabel }}</dd>
            <dd class="red">￥</dd>
            <dd>共 {{ projectList.length }} 项，合计</dd>
          </div>
        </div>

        <div v-if="paymentInfo && paymentInfo.needPassword === '1'">
          <div class="txb border_none_0px">
            <div style="display: flex; margin: 12px 16px 0 16px;">
              <div style="flex: 1;">
                {{ '付款帐号' }}
              </div>
              <div>
                <div>{{ bindingCardNo }}</div>
                <div style="font-size: 13px; float: right; margin-top: 4px; color: #999999;">{{ bindingCardBankName }}</div>
              </div>
            </div>
          </div>
          <div class="agree clear">
            <van-checkbox
                v-model="checked"
                shape="square"
                checked-color="#E9331E"
                icon-size="14px"/>
            我已阅读并同意
            <font @click="showNotice2 = true">《广发银行委托代扣协议书》</font>
          </div>
        </div>
      </div>

      <div class="bottom_btn">
        <button class="green long" @click="recharge">提交订单</button>
      </div>
      <div class="height_16vw"></div>

    </div>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>{{ loadingContent }}</van-loading>
    </div>

    <div v-if="step==2">
      <div>
        <div class="bank3_title">身份验证</div>
        <div class="bank3_sub_title">请验证支付密码确认本人操作</div>
        <div class="yzm2">
          <van-password-input
              :value="password"
              :gutter="10"
              :focused="showPasswordKeyboard"
              @focus="showPasswordKeyboard = true"
          />
        </div>
        <div style="text-align: center; padding-top: 20px;">
          <div
              style="position: absolute; padding-top: 28px; color: rgb(28, 122, 235); margin-right: 24px; right: 0; font-size: 12px;"
              @click="$router.push({path: '/verification'})">
            忘记支付密码?
          </div>
          <a-button type="link" @click="step=1" class='cancel_recharge'>
            取消充值
          </a-button>
        </div>
        <van-number-keyboard
            v-model="password"
            :maxlength="6"
            :show="showPasswordKeyboard"
            @blur="showPasswordKeyboard = false"
        />
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue'
import {ACCESS_CustomerUser} from '@/store/mutation-types'
import {post} from '@/utils/http'
import {formatAmount, guid} from "../../utils/util";
import {Dialog, Notify} from "vant";
import {TUITION_AND_FEES_CONFIRM_MODEL} from "../../store/mutation-types";
import {toDecimal} from "../../utils/decimals";

export default {
  name: "TuitionAndFeesDetail",
  components: {},
  data() {
    return {
      step: 1,
      password: "",
      showPasswordKeyboard: true,

      customerUser: null,
      id: 0,
      data: {},
      projectList: [],
      paymentInfo: {},
      bindingCardNo: '',
      bindingCardBankName: '',
      img: require('../../../src/assets/images/avatar3.png'),

      totalAmount: 0,
      totalAmountLabel: '0.00',

      checked: false,
      showNotice2: false,

      loading: false,
      loadingContent: "加载中...",

      sign: ""
    };
  },
  watch: {
    password(val) {
      if (val.length === 6) {
        this.confirmRechargeDepositAndRecharge();
      }
    }
  },
  mounted() {
    this.customerUser = Vue.ls.get(ACCESS_CustomerUser);
    if (this.customerUser === null) {
      this.$router.replace({path: '/index'})
      return;
    }

    const data = Vue.ls.get(TUITION_AND_FEES_CONFIRM_MODEL);
    if (data === null) {
      return;
    }
    this.sign = guid();
    this.data = data.data;
    this.projectList = data.projectList;
    for (let i = 0; i < this.projectList.length; i++) {
      this.totalAmount = this.totalAmount + this.projectList[i].amount;
    }
    this.totalAmountLabel = formatAmount(this.totalAmount);

    if (this.data.paymentPlatform.code === 'GF') {
      this.loadBusinessPaymentInfo();
    }

  },
  methods: {
    loadBusinessPaymentInfo() {
      post('api/customer/account/v2/own/read/cardNo', {})
          .then(res => {
            if (res.data) {
              this.bindingCardNo = res.data.bindingCardNo;
              this.bindingCardBankName = res.data.bindingCardBankName;
            }
          });
      post("api/business/v2/{businessId}/business/customer/user/paymentInfoQuery".replace('{businessId}', this.customerUser.businessId))
          .then(result => {
            this.paymentInfo = result.data;
          })
          .catch(error => {
          });
    },
    recharge() {
      if (!this.totalAmount || this.totalAmount <= 0) {
        Notify({type: 'warning', message: '金额异常，请重新下单'});
        return;
      }

      if (this.data.paymentPlatform.needPassword === '1') {
        if (!this.checked) {
          Notify({type: "danger", message: '请阅读和同意相关协议'});
          return;
        }
      }

      this.setLoading("检查充值环境中");

      if (this.data.paymentPlatform.code === 'GF') {
        // 增加限额控制
        // 检查是否二类户
        post('api/customer/account/v2/{businessCustomerUserId}/find'.replace("{businessCustomerUserId}", this.customerUser.businessCustomerUserId), {}, true)
            .then(res => {
              this.customerAccount = res.data

              if (this.customerAccount.accountOpenType === 'YL') {
                // 银联支付
                this.createRechargeDeposit();

              } else {
                // 广发
                if (this.customerAccount.accountOpenType === '3') {
                  // 检查影像状态？
                  // 初审成功
                  if (this.customerAccount.auditStatus === '01') {
                    this.loading = false;
                    Dialog.alert({title: '温馨提示', message: '您当前的充值限额为2000\n(当前账户正在升级中,请耐心等待审核结果)'});
                    return;
                  }

                  // 1. 获取广发余额
                  post('api/guangfa/v2/queryAccountBalance', {}).then(res => {
                    let balance = toDecimal(res.data.eAccAmt)
                    // 2. 根据 充值金额+广发余额 是否 > 2000 进行比较
                    if ((toDecimal(this.showAmount) + balance) > 2000) {
                      // 3. 大于2000 提示升级，进入升级流程
                      this.loading = false;
                      Dialog.confirm({title: '温馨提示', message: '您当前的充值限额为2000,需升级后才能正常使用', confirmButtonText: '升级'})
                          .then(() => {
                            this.$router.push({path: '/AccountUpgradeDescription'})

                          })
                          .catch(() => {

                          });

                    } else {
                      this.createRechargeDeposit();
                    }

                  }).catch(error => {
                    Notify({type: 'warning', message: error.message});
                    this.loading = false;

                  });

                } else if (this.customerAccount.accountOpenType === '2') {
                  if (this.customerAccount.auditStatus === '03') {
                    // 二审成功
                    this.createRechargeDeposit();
                    return;

                  }
                  if (this.customerAccount.auditStatus === '04') {
                    // 二审失败
                    this.$router.push({
                      path: '/AccountCreateFeedback',
                      query: {type: 'UPGRADE_FAIL', msg: this.customerAccount.auditRemark}
                    });

                  } else if (this.customerAccount.auditStatus === '05' || this.customerAccount.auditStatus === '06') {
                    // 等待影相再审核
                    this.$router.push({
                      path: '/AccountCreateFeedback',
                      query: {type: 'ID_CARD_REVIEWING'}
                    });

                  }
                  this.loading = false;
                }
              }

            }).catch(() => {
          Notify({type: 'warning', message: '充值异常，请重试'});
        });
      } else {
        // 银联支付
        this.createRechargeDeposit();
      }

    },
    // 1.下单
    createRechargeDeposit() {
      this.setLoading("生成充值订单中");

      this.deposit = {};

      let projectIdList = [];
      for (let i = 0; i < this.projectList.length; i++) {
        projectIdList = projectIdList.concat(this.projectList[i].id);
      }

      post('api/pay/deposit/miscellaneous/fees/recharge/create', {
        totalAmount: this.totalAmount,
        miscellaneousFeesId: this.data.id,
        miscellaneousFeesProjectIds: projectIdList,
        businessCustomerUserId: this.customerUser.businessCustomerUserId,
        sign: this.sign,
      }).then(result => {
        this.deposit = result.data;
        if (this.deposit) {
          if (this.deposit.needPassword === '1') {
            this.loading = false;
            this.step = 2;

          } else {
            // 直接确认
            this.confirmRechargeDepositAndRecharge();
          }

        }

      }).catch(error => {
        this.loading = false;
        Notify({type: "warning", message: error.message});

      }).finally(() => {
        this.sign = guid();
      });

    },
    // 2.确认
    confirmRechargeDepositAndRecharge() {
      if (!this.deposit) {
        if (this.step === 2) {
          Notify({type: "warning", message: "充值异常，请重新充值"});
          this.step = 1;

        } else {
          Notify({type: "warning", message: "充值异常，请重新充值（-1）"});

        }
        return;
      }

      this.setLoading("充值订单确认中");

      post('api/pay/deposit/miscellaneous/fees/recharge/confirm', {
        depositId: this.deposit.depositId,
        password: this.password
      }).then(result => {
        if (result.data && result.data.payUrl) {
          // 跳转第三方页面
          window.location.href = result.data.payUrl;
        } else {
          // 广发钱包支付
          Dialog.alert({message: '充值成功'}).then(() => {
            this.step = 1;
            this.$router.back(-1);
          });

        }

        this.loading = false

      }).catch(error => {
        if (error.message.indexOf('交易流水号:') != -1) {
          let no = error.message.split(':')[1]
          this.check(no)
        } else {
          Dialog.alert({title: '充值失败', message: error.message}).then(() => {
            this.setupData();
          });
        }
        this.loading = false
      });

    },
    setLoading(content) {
      this.loading = true;
      this.loadingContent = content;
    },
  }
};
</script>

<style scoped>
::v-deep body {
  background: #ffffff !important;
}

#app {
  height: 100vh;
  background: #fff;
}

.mine_t {
  height: 28vw;
  overflow: hidden;
  position: relative;
}

.mine_t::before {
  content: '';
  width: 100%;
  height: 3vw;
  background: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.tuition_tab {
  padding: 0 3vw;
  margin-bottom: 5vw;
}

.tuition_tab_l {
  float: left;
}

.tuition_tab_l ul li {
  height: 7vw;
  line-height: 7vw;
  padding: 0 2vw;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  float: left;
  margin-right: 3vw;
}

.tuition_tab_l ul li.now {
  background: #34ac40;
  border: 1px solid #34ac40;
  color: #fff;
}

.tuition_tab_r {
  float: right;
  height: 7vw;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: right;
  flex-direction: row;
  flex-wrap: wrap;
}

.tuition_tab_r span {
  margin-right: 2vw;
}

.tuition_list {
  width: 94vw;
  margin: 0 auto;
  margin-bottom: 5vw;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 3vw;
}

.tuition_list_t {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 4vw;
}

.tuition_list_t span {
  display: block;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 1vw;
}

.tuition_list_t label {
  display: block;
  color: #999;
}

.tuition_list_b {
  padding-top: 3vw;
}

.tuition_list_b ul li {
  float: left;
  margin-right: 3vw;
  padding-right: 3vw;
  position: relative;
}

.tuition_list_b ul li:first-child::after {
  content: '';
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: #e5e5e5;
}

.tuition_list_b ul li.black {
  color: #4B4B4B;
}

.tuition_list_b ul li.blue {
  color: #3485ff;
}

.tuition_list_b ul li.gray {
  color: #999999;
}

.tuition_list_b ul li.orange {
  color: #ff6034;
}

/* 选项 */
.tuition_list_t span {
  font-size: 18px;
}

.tuition_list.border_none {
  border: none;
  padding-top: 0;
}

.tuition_choose {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
  flex-wrap: wrap;
}

.tuition_choose.choose_all {
  padding: 2vw 0;
  padding-bottom: 4vw;
  border-bottom: 1px solid #e5e5e5;
}

.tuition_choose_l {
  width: 10%;
  height: 100%;
  float: left;
}

.tuition_choose_r {
  width: 100%;
  float: right;
  border-bottom: 1px solid #f2f2f2;
  padding: 2vw 0;
  margin-bottom: 2vw;
}

.tuition_choose_r span {
  display: block;
  font-weight: bold;
  font-size: 16px;
}

.tuition_choose_r span font {
  float: right;
  background: #ddffe0;
  color: #34ac40;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 3px;
  font-weight: normal;
}

.tuition_choose_r span font.gray {
  background: #f2f2f2;
  color: #999;
  font-weight: normal;
}

.tuition_choose_r label {
  display: block;
  color: #f66;
}

.tuition_choose_r.choose_all {
  border: none;
  padding: 0;
  margin: 0;
}

.tuition_choose_r.choose_all dt {
  float: left;
}

.tuition_choose_r.choose_all dd {
  float: right;
  line-height: 18px;
}

.tuition_choose_r.choose_all dd.red {
  color: #f66;
  font-weight: bold;
}

.tuition_choose_r.choose_all dd.red_big {
  color: #f66;
  font-size: 18px;
  font-weight: bold;
}

.bottom_btn button.long {
  width: 94vw;
  margin: 0 auto;
}

.height_16vw {
  height: 16vw;
}
</style>